import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/ProductSelection',
  },
  {
    path: '/ProductSelection',
    name: 'ProductSelection',
    component: () => import('../views/ProductSelection.vue'),
  },
  {
    path: '/OrderInfo',
    name: 'OrderInfo',
    component: () => import('../views/OrderInfo.vue'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue'),
  },
  {
    path: '/orderSuccess',
    name: 'orderSuccess',
    component: () => import('../views/orderSuccess.vue'),
  },
  {
    path: '/home',
    name: 'index',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/sing',
    name: 'sing',
    component: () => import('../views/sing.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
